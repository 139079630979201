module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-99078705-1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"The Golden Rule","short_name":"Golden Rule","icons":[{"src":"/favicons/golden-rule-192.png","sizes":"192x192","type":"image/png"},{"src":"/favicons/golden-rule-512.png","sizes":"512x512","type":"image/png"}],"start_url":"/","background_color":"#fff","theme_color":"#ff5b8f","display":"minimal-ui"},
    }]
